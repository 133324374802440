import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.fieldValid(e.target.name)) {
            validator.hideMessageFor(e.target.name);
        } else {
            validator.showMessageFor(e.target.name);
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            
            const templateParams = {
                name: forms.name,
                email: forms.email,
                phone: forms.phone,
                message: forms.message,
                to_email: 'admin@graysharborlaw.com'
            };

            emailjs.send(
                'service_e0fiihq',
                'template_cghuyho',
                templateParams,
                '6l0I7cYCmQYodjkgV' 
            ).then(response => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
                setForms({ name: '', email: '', phone: '', message: '' });
            }).catch(err => {
                console.log('FAILED...', err);
                alert('Failed to send message, please try again later.');
            });
        } else {
            validator.showMessages();
        }
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <form method="post" className="contact-validation-active" id="contact-form-main" onSubmit={(e) => submitHandler(e)}>
            <div className="form-field">
                <input
                    value={forms.name}
                    type="text"
                    name="name"
                    className="form-control"
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    placeholder="Name*" />
                {validator.message('name', forms.name, 'required|alpha_space')}
            </div>
            <div className="form-field">
                <input
                    value={forms.email}
                    type="email"
                    name="email"
                    className='form-control'
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    placeholder="Email*" />
                {validator.message('email', forms.email, 'required|email')}
            </div>
            <div className="form-field">
                <input
                    value={forms.phone}
                    type="phone"
                    name="phone"
                    className='form-control'
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    placeholder="Your phone" />
                {validator.message('phone', forms.phone, 'required|phone')}
            </div>
            <div className="form-field fullwidth">
                <textarea
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    value={forms.message}
                    type="text"
                    name="message"
                    className='form-control'
                    placeholder="Case Description...">
                </textarea>
                {validator.message('message', forms.message, 'required')}
            </div>
            <div className="submit-area thirdwidth">
                <button type="submit" className="theme-btn-s3">Submit</button>
            </div>
            <div className="italictext twothirdwidth">By submitting you agree to our <Link onClick={ClickHandler} to='/privacy'>privacy policy</Link>. Submitting this form does not constitute attorney-client privilage. We do not accept any service of legal process or correspondence from opposing parties or their counsel through our website.</div>
        </form>
    )
}

export default ContactForm;