import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import Hero2 from '../../components/hero2';
import About from '../../components/about/about';
import Features from '../../components/Features/Features';
import PracticeS2 from '../../components/PracticeS2/PracticeS2';
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
import ContactSection from '../../components/ContactSection/ContactSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = () => {
    return (
        <Fragment>
            <Navbar3/>
            <Hero2 />
            <About />
            <PracticeS2 />
            <Features />
            <Testimonial2 />
            <ContactSection contactClass={'contact-section-s3'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;