import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = (props) => {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const testimonial = [
        {
            Des: "Kelsey was wonderful. She’s one of the most educated women I have ever met in my entire life when it comes to law. She’s very good with explaining things and she make sure that both sides get a fair shot.",
            Title: 'Sidney Irene',
        },
        {
            Des: "Kelsey has been kind, compassionate, knowledgeable, and competent in handling our son's case. They have high integrity and admirably maintain respect for clients, judges, and opposing attorneys, even when the points of contention are strong.",
            Title: 'Gerrit Hansen',
        },
        {
            Des: "Kelsey's professionalism and comprehensive guidance were remarkable. Her consistent communication, timely updates, and clear explanations of procedures kept me well-informed at every step of the process.",
            Title: 'Jessica Hulet',
        },
        {
            Des: "I have had an excellent experience with my attorney Kelsey Demeter. She has been extremely hard working, helpful and responsive throughout my custody case. I cannot say enough good things about her and I am very grateful! I am happy I found such a great lawyer!",
            Title: 'Clayton Herron',
        },
        {
            Des: "Kelsey Demeter did an excellent job throughout the whole process. Her expertise was invaluable in making everything as smooth as possible.",
            Title: 'Emilee Quinlan',
        },
        {
            Des: "Kelsey was very detailed in everything that she did. When my child custody case finally went before the judge, she was prepared for every question that came from the opposing attorney, the GAL, and the Judge",
            Title: 'Joshua Sanchez',
        },
        {
            Des: "It was obvious that Kelsey has handled many cases and is extremely competent. I rate my experience with Kelsey 5 STARS!",
            Title: 'Jessica Hulet',
        },
        {
            Des: "Fantastic attorney, extremely knowledgeable and passionate, I would trust Kelsey to defend my parental rights!",
            Title: 'Benjamin Nussle',
        },
    ]
    return (

        <section className={`testimonials-section section-padding ${props.tClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-5">
                        <div className="section-title-s4">
                            <div className="icon">
                                <i className="fi flaticon-blocks-with-angled-cuts"></i>
                            </div>
                            <span>Testimonials</span>
                            <h2>What our Clients say About us</h2>
                            <p>See what our clients have to say about their experience working with The Law Offices of Kelsey Demeter. We take pride in delivering compassionate and effective legal representation.</p>
                        </div>
                    </div>
                    <div className="col col-lg-7">
                        <div className="testimonials-grids testimonials-slider">
                            <Slider {...settings}>
                                {testimonial.map((tesmnl, tsm) => (
                                    <div className="grid" key={tsm}>
                                        <i className="fi flaticon-left-quote"></i>
                                        <p>{tesmnl.Des}</p>
                                        <div className="client-info">
                                            <h3>{tesmnl.Title}</h3>
                                            <span>{tesmnl.Sub}</span>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;