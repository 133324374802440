import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import FaqSection from './FaqSection'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'

const FaqPage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <PageTitle pageTitle={'FAQ'} pagesub={'FAQ'} />
            <section className="faq-pg-section section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-xl-6 col-lg-8 col-md-10 col-12">
                            <div className="section-title-s3">
                                <div className="icon">
                                    <i className="fi flaticon-suitcase"></i>
                                </div>
                                <h2>Frequently Asked questions:</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel-group faq-accordion theme-accordion-s1" id="accordion">
                                <FaqSection />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default FaqPage;

