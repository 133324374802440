import React from 'react'

const HeaderTopbar2 = () => {
    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 col-12">
                        <div className="contact-info">
                            <ul className="clearfix">
                                <li><i className="ti-mobile"></i> (360) 660-5406</li>
                                <li><i className="ti-email"></i> admin@graysharborlaw.com</li>
                                <li><i className="ti-location-pin"></i> 113 S. First St., Montesano, WA 98563</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar2;