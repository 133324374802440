import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar3 from '../../components/Navbar3/Navbar3'
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Testimonial from '../../components/Testimonial/Testimonial';

const AboutCompanyPage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <PageTitle pageTitle={'The Law Offices of Kelsey Demeter'} pagesub={'The Law Offices of Kelsey Demeter'} />
            <section className="container my-5">
                {/* About the Firm Section */}
                <div className="row">
                    <div className="col-12">
                    <h2 className="text-center mb-4">About the Firm</h2>
                    <p>
                        At The Law Offices of Kelsey Demeter, we understand that legal matters involving your family are some of the most personal and emotionally charged challenges you may ever face. We are committed to providing compassionate guidance through every step of your legal journey. Whether you're navigating a divorce, child custody dispute, or any other family-related issue, we approach your case with the utmost sensitivity and care.
                    </p>
                    <p>
                        We recognize that each family is unique, and we take the time to listen to your concerns, understand your goals, and create a strategy that aligns with your best interests. However, when it comes time to advocate for you, we fight aggressively and tenaciously to protect your rights and ensure the most favorable outcome.
                    </p>
                    <p>
                        At our firm, we combine empathy with a fierce dedication to justice, offering you the support you need during difficult times, while standing firmly by your side in the pursuit of your and your family’s future.
                    </p>
                    </div>
                </div>

                {/* About Kelsey Demeter Section */}
                <div className="row mt-5">
                    <div className="col-12">
                    <h2 className="text-center mb-4">About Kelsey Demeter</h2>
                    <p>
                        A Pacific Northwest native, I have been in the legal field for over a decade working in various types of law. I began my career as a Paralegal and Hearings Supervisor at a large Social Security Disability firm in Oregon before relocating to Seattle, where I worked in Collections and Construction Defect litigation. During and directly after Law School, I worked in Maritime Personal Injury, helping fishermen who had been injured on the job in Alaska.
                    </p>
                    <p>
                        While in Law School, I was awarded a Dean’s Diversity Scholarship as well as the Crandall and Pera Law Legal Scholarship, which is an annual scholarship awarded to incoming law students who demonstrate a commitment to diversity and equality and consider helping those less fortunate a calling that they wish to answer. I was also accepted into and attended the Comparative Corporate Governance legal program at Oxford in England, where I was taught by the former Delaware Supreme Court Chief Justice.
                    </p>
                    <p>
                        During Law School, I externed for the Honorable Judge Gretchen Leanderson (ret.) in Pierce County during her Family Law Rotation. This experience watching seasoned attorneys practice family law gave me the jump when I started practicing because I gained firsthand insight into what arguments a judge finds most persuasive in domestic matters.
                    </p>
                    <p>
                        In addition to my main practice of Family Law, I am a certified Title 11 and Title 26 GAL, a member of the Tokeland and Shoalwater Bay Tribal Court, and a Special Prosecutor for the City of South Bend. I am Secretary of the Grays Harbor Bar Association, the King County Bar Association, and DRAW (Domestic Relation Attorneys of Washington).
                    </p>
                    </div>
                </div>
            </section>
            <Testimonial tClass={'testimonials-pg-section'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutCompanyPage;
