import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/demeterlogosmallbrown.png'
import Practices from '../../api/Practices';



const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <h3>About us</h3>
                                </div>
                                <p>At The Law Offices of Kelsey Demeter, we provide compassionate and dedicated legal services, specializing in family law matters. With years of experience, we are committed to guiding clients through difficult times with care, while fiercely advocating for their rights.</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget contact-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Our Address</h3>
                                </div>
                                <ul>
                                    <li>113 S. First St., Montesano, WA 98563</li>
                                    <li>(360) 660-5406</li>
                                    <li>admin@graysharborlaw.com</li>
                                    <li><span>Office Time:</span> 9AM - 5PM</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="practice/Family-Law">Our practices</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                </ul>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget line-widget-2">
                                <div className="widget-title">
                                    <h3>Practice Areas</h3>
                                </div>
                                <ul>
                                    {Practices.slice(0, 5).map((practice, Sitem) => (
                                        <li key={Sitem}><Link onClick={ClickHandler} to={`/practice/${practice.slug}`}>{practice.sTitle}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-xs-12">
                            <p className="copyright">Copyright &copy; 2025 Law Offices of Kelsey Demeter. All rights reserved.</p>
                            <div className="extra-link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/privacy">Privacy Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;