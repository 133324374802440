import React from 'react'
import abimg from '../../images/headshotreplacement370x406.png'
import { Link } from 'react-router-dom';

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about-section section-padding-small">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-12">
                        <div className="left-col">
                            <div className="section-title">
                                <div className="icon">
                                    <i className="fi flaticon-home-3"></i>
                                </div>
                                <h2>Family Law Representation You Deserve</h2>
                                <p>At The Law Offices of Kelsey Demeter, we provide compassionate and dedicated legal representation tailored to your family’s needs. We fight for your rights and work tirelessly to ensure a fair resolution, offering you the support you need during challenging times.</p>
                                <Link onClick={ClickHandler} to="/about" className="more-about">Read More About us <i className="fi flaticon-next-1"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="mid-col">
                            <img src={abimg} alt="" />
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="right-col">
                            <p>As your attorney, I will fight relentlessly to protect your rights and interests. I understand how important your case is, and I am committed to providing you with aggressive, strategic representation at every step. Whether it's negotiating a fair settlement or standing firm in court, you can trust that I will advocate for you with determination and care, ensuring that your voice is heard and your rights are safeguarded.</p>
                            <div className="quoter">
                                <h4>Kelsey Demeter</h4>
                                <span>- Attorney and Owner</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;