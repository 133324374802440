import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';

const PrivacyPage = (props) => {


    return (
        <Fragment>
            <Navbar3 hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <section className="wpo-terms-section section-padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="wpo-terms-wrap">
                                <div className="wpo-terms-text">
                                    <h2>Privacy Policy</h2>
                                    <p>At The Law Offices of Kelsey Demeter, we value your privacy and are committed to protecting the personal information you share with us. This Privacy Policy outlines the types of information we collect, how we use and protect that information, and your rights regarding your personal data.</p>
                                </div>
                                <div className="wpo-terms-text">
                                    <h4>Information We Collect</h4>
                                    <p>When you visit our website, we may collect certain personal information, including but not limited to your name, email address, phone number, and any other details you provide through forms or communication with us. This information is used to respond to inquiries, provide legal services, and improve the functionality of our website.</p>
                                    <p>We may also collect non-personally identifiable information, such as browsing behavior, IP addresses, and device information, to enhance the user experience on our website. This data is used to analyze website traffic and improve content.</p>
                                </div>
                                <div className="wpo-terms-text">
                                    <h4>How We Use Your Information</h4>
                                    <p>The personal information we collect is used solely for the purpose of providing legal services, responding to inquiries, and offering additional information or resources related to our practice areas. We do not sell or share your personal data with third parties unless required by law or to provide the services you request.</p>
                                    <p>We take reasonable precautions to protect the information you provide and ensure that it is kept secure. However, please be aware that no method of data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your personal information.</p>
                                </div>
                                <div className="wpo-terms-text">
                                    <h4>Your Rights and Choices</h4>
                                    <p>You have the right to access, update, or delete the personal information we have collected about you. If you would like to request any changes to your data or if you wish to opt out of receiving communications from us, please contact us at admin@graysharborlaw.com.</p>
                                    <p>By using our website, you consent to our privacy practices as described in this policy. We reserve the right to update this privacy policy at any time, for any reason.</p>
                                    <p>If you have any questions or concerns regarding this Privacy Policy, please don't hesitate to contact us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PrivacyPage;
