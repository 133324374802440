import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero2 from '../../images/slider/Montesano-Courthouse.jpg'
import rainier from '../../images/rainier.jpg'
import family from '../../images/family.jpg'

const Hero2 = () => {
    return (

        <section className="hero-slider hero-style-2">
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                        <div className="container">
                            <div data-swiper-parallax="300" className="slide-text">
                                <p>Fighting for Your Family's Best Interests</p>
                            </div>
                            <div data-swiper-parallax="400" className="slide-title">
                                <h2>Compassionate Support - Relentless Advocacy</h2>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                <Link to="/contact" className="theme-btn">Free Consultation</Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${rainier})` }}>
                        <div className="container">
                            <div data-swiper-parallax="300" className="slide-text">
                                <p>Empathy in Every Step, Strength in Every Argument</p>
                            </div>
                            <div data-swiper-parallax="400" className="slide-title">
                                <h2>Your Family, Our Priority</h2>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                <Link to="/contact" className="theme-btn">Free Consultation</Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${family})` }}>
                        <div className="container">
                            <div data-swiper-parallax="300" className="slide-text">
                                <p>Guiding You Through Life's Toughest Moments</p>
                            </div>
                            <div data-swiper-parallax="400" className="slide-title">
                                <h2>Protecting What Matters Most</h2>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                <Link to="/contact" className="theme-btn">Free Consultation</Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                ...
            </Swiper>
        </section>

    )
}

export default Hero2;