import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import HeaderTopbar2 from '../HeaderTopbar2';
import Logo from '../../images/DemeterLogo.png'
import Practices from '../../api/Practices';


const Header3 = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header id="header" className={`site-header header-style-3 ${props.hclass}`}>
            <HeaderTopbar2 />
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                        <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">About</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/practice/family-law">Practice Areas</Link>
                                <ul className="sub-menu">
                                    {Practices.slice(0, 5).map((practice, Pitem) => (
                                        <li key={Pitem}><Link onClick={ClickHandler} to={`/practice/${practice.slug}`}>{practice.sTitle}</Link></li>
                                    ))}
                                </ul>
                            </li>
                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default Header3;
