import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
import Footer from '../../components/footer/Footer';
import ContactSection from '../../components/ContactSection/ContactSection';


const TestimonialPage2 =() => {
    return(
        <Fragment>
            <Navbar3 hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={'Testimonials'} pagesub={'Testimonials'}/> 
            <Testimonial2/>
            <ContactSection contactClass={'contact-section-s3'} />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage2;
