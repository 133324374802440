import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import HomePage from '../HomePage/HomePage';
import AboutCompanyPage from '../AboutCompanyPage/AboutCompanyPage';
import TestimonialPage from '../TestimonialPage/TestimonialPage';
import PracticeSinglePage from '../PracticeSinglePage/PracticeSinglePage';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import FaqPage from '../FaqPage';
import PrivacyPage from '../PrivacyPage/PrivacyPage';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="about" element={<AboutCompanyPage />} />
          <Route path="practice/:slug" element={<PracticeSinglePage />} />
          <Route path="testimonials" element={<TestimonialPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='privacy' element={<PrivacyPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
