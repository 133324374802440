import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Practices from '../../api/Practices';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const validSlugs = [
    "family-law",
    "contempt-of-court",
    "divorce-dissolution-of-marriage",
    "alimony-or-maintenance",
    "non-contested-divorce-or-modification",
    "committed-intimate-relationships",
    "property-division",
    "child-custody-visitation",
    "residential-relocation",
    "child-support-and-modifications",
    "post-secondary-educational-support",
    "paternity",
    "adoptions",
    "nonparental-custody",
    "adult-guardianship",
    "minor-guardianship",
    "adult-conservatorship",
    "minor-conservatorship",
    "emergency-guardianship"
  ];

const PracticeSinglePage = (props) => {
    let { slug } = useParams();
    const navigate = useNavigate();
    slug = slug.toLowerCase();

    React.useEffect(() => {
        console.log(slug)
      if (!validSlugs.includes(slug.toLowerCase())) {
        navigate('/error'); // Redirect to error page if slug is invalid
      }
    }, [slug, navigate]);

    const PracticeDetails = Practices.find(item => item.slug === slug)

    if (!!PracticeDetails) return (
        <Fragment>
            <Navbar3 hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={PracticeDetails.sTitle} pagesub={'Project'} />

            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 col-12 order-lg-2 order-1">
                            <div className="service-single-content">
                                <h2>{PracticeDetails.sTitle2}</h2>
                                <p>{PracticeDetails.description1}</p>
                                <p>{PracticeDetails.description2}</p>
                                <h3>{PracticeDetails.title2}</h3>
                                <p>{PracticeDetails.des2}</p>
                                <h3>{PracticeDetails.title3}</h3>
                                <p>{PracticeDetails.des3}</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-12 order-lg-1 order-2">
                            <div className="service-sidebar">
                                <div className="widget service-list-widget">
                                    <ul>
                                        {Practices.map((practice, Pitem) => (
                                            <li className={PracticeDetails.Id === practice.Id ? 'current' : ''} key={Pitem}><Link onClick={ClickHandler} to={`/practice/${practice.slug}`}>{practice.sTitle}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="widget contact-widget">
                                    <div>
                                        <h4>Get in touch</h4>
                                        <p>Ready to discuss your case with an experienced attorney? Click below to contact Kelsey Demeter and get the personalized legal guidance you need.</p>
                                        <Link onClick={ClickHandler} to="/contact">Contact Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PracticeSinglePage;
