import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const faqData = [
    {
        question: "What is family law?",
        answer: "Family law covers a range of legal issues related to family relationships, such as divorce, child custody, child support, adoption, and domestic violence. It deals with both the legal and emotional aspects of family matters, ensuring that the best interests of all parties are protected."
    },
    {
        question: "What should I expect during the divorce process?",
        answer: "Divorce can be a complex and emotional process. Expect to work through issues like property division, child custody, and support arrangements. Kelsey Demeter will guide you through each step, ensuring that your rights are protected and that the outcome aligns with your needs and goals."
    },
    {
        question: "How is child custody determined?",
        answer: "Child custody is determined based on the child's best interests. Factors considered include the child's age, emotional needs, and each parent's ability to care for them. Kelsey Demeter works closely with you to present the strongest case possible to achieve a custody arrangement that benefits both you and your child."
    },
    {
        question: "Can I modify my child custody or support arrangement?",
        answer: "Yes, if there has been a significant change in circumstances, such as a parent's relocation, changes in income, or the child’s needs, you can seek to modify child custody or support arrangements. Kelsey Demeter can help you navigate the modification process to ensure it meets your family’s evolving needs."
    },
    {
        question: "What is alimony, and how is it determined?",
        answer: "Alimony (or spousal support) is financial assistance provided to a spouse after a divorce. It is determined based on factors such as the length of the marriage, each spouse’s financial situation, and the recipient’s need for support. Kelsey Demeter will work with you to understand your eligibility and advocate for a fair alimony arrangement."
    },
    {
        question: "How long does the divorce process take?",
        answer: "The timeline for a divorce can vary based on factors such as whether it is contested or uncontested, the complexity of the issues involved, and the local court’s schedule. On average, a divorce can take anywhere from a few months to over a year. Kelsey Demeter will strive to streamline the process while ensuring that your rights are fully protected."
    },
    {
        question: "What is the difference between legal custody and physical custody?",
        answer: "Legal custody refers to the right to make decisions regarding the child’s upbringing, including education, healthcare, and religious instruction. Physical custody refers to where the child will live. It’s important to clearly define these terms during custody negotiations to avoid confusion."
    },
    {
        question: "What should I do if I need a protective order?",
        answer: "If you are facing abuse or threats, you may be able to obtain a protective order (also known as a restraining order). This legal document can provide immediate protection and restrict the abuser’s contact with you. Kelsey Demeter will help you navigate the legal process to secure the protection you need."
    },
    {
        question: "How can I handle disputes regarding property division in divorce?",
        answer: "Property division in divorce can be contentious. Washington is a community property state, which means assets and debts acquired during the marriage are typically divided equally. However, there are exceptions and factors that may affect the division. Kelsey Demeter will ensure that your property rights are protected and help you achieve a fair outcome."
    },
    {
        question: "What should I do if I am involved in a child custody dispute?",
        answer: "If you are involved in a child custody dispute, it’s important to remain focused on the best interests of the child. It’s vital to gather evidence, remain professional, and avoid conflict. Kelsey Demeter will advocate for your parental rights, ensuring that the court makes decisions that are in the best interest of your child."
    }
];




const FaqSection = (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        {faqData.map((item, index) => (
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                                <AccordionSummary
                                    expandIcon=""
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <Typography>{item.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FaqSection;