import React from 'react'
import ContactForm from '../ContactForm';

const Contactpage = (props) => {
    return (
        <div>
            <section className="contact-section-s3">
                <div className="content-area clearfix">
                    <div className="contact-info-col">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <i className="fi flaticon-home-3"></i>
                                    <h4>Office</h4>
                                    <p>113 S. First St., Montesano, WA 98563</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-email"></i>
                                    <h4>Email Address</h4>
                                    <p>admin@graysharborlaw.com</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-support"></i>
                                    <h4>Telephone</h4>
                                    <p>(360) 660-5406</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-clock"></i>
                                    <h4>Office Hour</h4>
                                    <p>Mon-Fri: 9am – 5pm</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contact-form-col contact-padding">
                        <div className="section-title-s2">
                            <div className="icon">
                                <i className="fi flaticon-balance"></i>
                            </div>
                            <h2>Need a consult? <br />Request A Free Quote</h2>
                            <p>We understand that facing legal challenges can be overwhelming. Whether you have questions or need immediate assistance, we are here to help. Fill out the form below, and Kelsey Demeter will get back to you as soon as possible to discuss how we can assist you with your legal needs.</p>
                        </div>

                        <div className="contact-form">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-map-section section-padding pb-0">
                <div className="contact-map">
                    <iframe title='contact-map' width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=113%20S.%20First%20St.,%20Montesano,%20WA%2098563+(Law%Offices%of%20Kelsey%20Demeter)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </section>
        </div>
    )
}

export default Contactpage;