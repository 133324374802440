import React from 'react'
import {Link} from 'react-router-dom'

const Features = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="feature-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="info-col">
                            <h4>Kelsey Demeter will get you the legal representation you deserve</h4>
                            <Link onClick={ClickHandler} to="/contact" className="theme-btn-s2">Contact with us</Link>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <i className="fi flaticon-diamond"></i>
                            <h3>Compassionate Legal Support</h3>
                            <p>Navigating family law matters can be emotional and overwhelming. We provide compassionate guidance and personalized strategies to help you move forward with confidence.</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <i className="fi flaticon-balance"></i>
                            <h3>Experienced & Tenacious Representation</h3>
                            <p>With over a decade of legal experience, Kelsey Demeter combines in-depth knowledge with relentless advocacy to protect your rights and secure the best possible outcome.</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <i className="fi flaticon-mace"></i>
                            <h3>Justice with a Personal Touch</h3>
                            <p>Every family is unique. We take the time to listen, understand your goals, and fight for what matters most to you and your future.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;